/* Fonts embeded */
@font-face {
  font-family: "Geomanist";
  src: url("../public/fonts/Geomanist-Regular.otf") format("opentype");
}

:root {
  --primary: #e33b54;
  --secondary: #2ebd85;
  --muted: #cbd5e1;
  --error: #ff4d4f;
}

* {
  font-family: "Geomanist" !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

body {
  font-size: 16px;
  font-family: var(--font-family) !important;
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}

html::-webkit-scrollbar,
.scroll-ruler::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f1f5f9;
}
html::-webkit-scrollbar-thumb,
.scroll-ruler::-webkit-scrollbar-thumb {
  background-color: var(--muted);
  border-radius: 10px;
}
html::-webkit-scrollbar-track,
.scroll-ruler::-webkit-scrollbar-track {
  border-color: var(--muted);
}

input {
  font-size: 16px !important;
}

.service-packages::before {
  position: fixed;
  background: radial-gradient(
    circle,
    rgba(227, 59, 84, 0.28904061624649857) 0%,
    rgba(255, 255, 255, 1) 73%
  );
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--secondary) !important;
}

.ant-checkbox-inner {
  border-color: var(--secondary) !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: var(--secondary) !important;
}

.ant-switch.ant-switch-checked {
  background-color: var(--secondary) !important;
}
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background-color: var(--secondary) !important;
  opacity: 0.85;
}

.ant-input-outlined:focus:not(.ant-input-status-error),
.ant-input-outlined:focus-within:not(.ant-input-status-error),
.ant-input-outlined:hover:not(.ant-input-status-error) {
  border-color: var(--secondary) !important;
}

.ant-btn.ant-btn-primary:not(:disabled) {
  background-color: var(--primary);
  color: #fff;
  opacity: 1;
  border-radius: 6px;
  transition: all 0.25s linear;
  box-shadow: none;
}

.ant-btn.ant-btn-primary:not(:disabled).success {
  background-color: var(--secondary) !important;
}

.ant-btn.ant-btn-primary:not(:disabled):hover {
  opacity: 0.9;
  background-color: var(--primary) !important;
}

.ant-btn.ant-btn-primary:not(:disabled):hover.success {
  background-color: var(--secondary) !important;
}

.ant-btn.ant-btn-default:not(:disabled):hover {
  border-color: var(--primary) !important;
  color: var(--primary) !important;
  transition: all 0.25s linear;
}

.ant-btn.ant-btn-default.success {
  background-color: #0bcdac26 !important;
  color: var(--secondary) !important;
  border: none;
  border-radius: 999px;
  padding: 6px 16px;
  transition: all 0.25s linear;
  box-shadow: none;
}

.ant-btn.ant-btn-default:hover.success {
  opacity: 0.8;
  background-color: #0bcdac26 !important;
  color: var(--secondary) !important;
}

.ant-btn.ant-btn-default:disabled.success {
  opacity: 0.7;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: var(--secondary) !important;
  background-color: var(--secondary) !important;
}

.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: var(--secondary) !important;
}

.ant-radio.ant-radio-disabled {
  display: none;
}
.show-radio .ant-radio.ant-radio-disabled {
  display: inline-block !important;
}

.ant-spin-dot-holder,
.ant-spin-dot .ant-spin-dot-spin {
  color: var(--secondary) !important;
}

.ant-upload-wrapper,
.ant-upload-wrapper [class*=" ant-upload"] {
  width: 100%;
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #0bcdac26 !important;
}

.ant-select .ant-select-selector {
  border-color: var(--secondary) !important;
}

.ant-select-item-option-state {
  color: var(--secondary) !important;
}

.ant-select:hover .ant-select-selector {
  border-color: var(--secondary) !important;
}

.ant-form-item-explain-error {
  color: red;
  margin-bottom: 0.25rem;
  display: block;
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center;
}

.tabs-folder .ant-tabs-content-holder {
  display: none !important;
}
.tabs-folder .ant-tabs {
  height: fit-content !important;
}

/* PhotoSwipe */
.pswp {
  z-index: 100;
}

.pswp__bg {
  opacity: 0.95 !important;
}

.pswp__dynamic-caption {
  color: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  transition: opacity 120ms linear !important;
}

.pswp__dynamic-caption a {
  color: #fff;
}

.pswp__dynamic-caption--faded {
  opacity: 0 !important;
}

.pswp__dynamic-caption--aside {
  width: auto;
  max-width: 300px;
  padding: 20px 15px 20px 20px;
  margin-top: 70px;
}

.pswp__dynamic-caption--below {
  width: 100% !important;
  left: 0 !important;
  right: 0 !important;
}

/* .pswp__dynamic-caption--on-hor-edge {
  padding-left: 15px;
  padding-right: 15px;
} */

.pswp__dynamic-caption--mobile {
  width: 100%;
  background: transparent;
  padding: 10px 15px;
  right: 0;
  bottom: 0;
  top: auto !important;
  left: 0 !important;
}

.pswp__recommended-button {
  display: flex;
  align-items: center;
}

.pswp__img {
  cursor: default !important;
}

h2 {
  text-align: center;
  padding-bottom: 2rem;
}
h2 > strong {
  font-size: 2rem;
}

p {
  font-size: 0.9rem;
}

strong {
  font-size: 1rem;
}

ul {
  font-size: 0.9rem;
}

ul li {
  list-style-type: disc;
  margin-left: 2rem;
  font-size: 0.9rem;
}

#heart {
  position: relative;
  width: 100px;
  height: 90px;
  animation: heartbeat 1s infinite;
  margin: 0 auto;
}
#heart:before,
#heart:after {
  position: absolute;
  content: "";
  left: 50px;
  top: 0;
  width: 50px;
  height: 80px;
  background: red;
  -moz-border-radius: 50px 50px 0 0;
  border-radius: 50px 50px 0 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
#heart:after {
  left: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}
.scroller {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
}

.scroller .scroller-item {
  scroll-snap-align: start;
  min-height: 100vh;
}

#gallery .is-liked {
  height: auto !important;
}

#gallery .is-liked svg {
  height: 22px !important;
  width: 22px !important;
}

#gallery .is-liked .text-white {
  color: rgb(75 85 99 / var(--tw-text-opacity)) !important;
}
